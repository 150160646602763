var WorkerHelper = function () {}
WorkerHelper.checkPointDistanceToPlane = function (planeNormal, planeCoplanarPoint, point) {
    WorkerHelper.normalize(planeNormal);
    var c = -WorkerHelper.dot(planeCoplanarPoint, planeNormal);
    return WorkerHelper.dot(planeNormal, point) + c;
};
WorkerHelper.normalize = function (normal) {
    var len = Math.sqrt(normal.x * normal.x + normal.y * normal.y + normal.z * normal.z);
    WorkerHelper.divideScalar(normal, len);
};
WorkerHelper.divideScalar = function (vec, scalar) {
    vec.x /= scalar;
    vec.y /= scalar;
    vec.z /= scalar;
};
WorkerHelper.dot = function (v0, v1) {
    return v0.x * v1.x + v0.y * v1.y + v0.z * v1.z;
};
WorkerHelper.getDistance = function (p0, p1) {
    var dx = p1.x - p0.x;
    var dy = p1.y - p0.y;
    var dz = p1.z - p0.z;
    return Math.sqrt(dx * dx + dy * dy + dz * dz);
};
WorkerHelper.rotateVecAroundAxis = function (axisVec, rotateVec, angle) {
    var c = Math.cos(angle);
    var s = Math.sin(angle);
    var t = 1 - c;
    var x = axisVec.x, y = axisVec.y, z = axisVec.z;
    var tx = t * x, ty = t * y;

    var mat4 = [
        tx * x + c, tx * y - s * z, tx * z + s * y, 0,
        tx * y + s * z, ty * y + c, ty * z - s * x, 0,
        tx * z - s * y, ty * z + s * x, t * z * z + c, 0,
        0, 0, 0, 1
    ];
    var vx = rotateVec.x, vy = rotateVec.y, vz = rotateVec.z;
    rotateVec.x = mat4[0] * vx + mat4[4] * vy + mat4[8] * vz + mat4[12];
    rotateVec.y = mat4[1] * vx + mat4[5] * vy + mat4[9] * vz + mat4[13];
    rotateVec.z = mat4[2] * vx + mat4[6] * vy + mat4[10] * vz + mat4[14];
};
WorkerHelper.addVecs = function (v0, v1) {
    return {x: v0.x + v1.x, y: v0.y + v1.y, z: v0.z + v1.z};
};
WorkerHelper.multiplyScalarVec = function (vec, scalar) {
    return {x: vec.x * scalar, y: vec.y * scalar, z: vec.z * scalar};
};
WorkerHelper.checkPointInPolygon = function (point, pts) {
    var t = -1;

    var p0 = {x: Math.round(point.x * 1000), y: Math.round(point.z * 1000)};
    var testJordan = function (a, b, c) {
        if (a.y == b.y && b.y == c.y) {
            if ((b.x <= a.x && a.x <= c.x) || (c.x <= a.x && a.x <= b.x)) {
                return 0;
            }
            else {
                return 1;
            }
        }
        if (b.y > c.y) {
            var t0 = c.x;
            c.x = b.x;
            b.x = t0;
            t0 = c.y;
            c.y = b.y;
            b.y = t0;
        }
        if (a.y == b.y && a.x == b.x) {
            return 0;
        }
        if (a.y <= b.y || a.y > c.y) {
            return 1;
        }
        var d = (b.x - a.x) * (c.y - a.y) - (b.y - a.y) * (c.x - a.x);
        if (d > 0) {
            return -1;
        }
        else if (d < 0) {
            return 1;
        }
        return 0;
    };
    var res = 0;
    for (var i = 0; i < pts.length - 1; i++) {
        res = testJordan(p0, {x: pts[i].x, y: pts[i].y}, {x: pts[i + 1].x, y: pts[i + 1].y});
        t *= res;
    }
    res = testJordan(p0, {x: pts[pts.length - 1].x, y: pts[pts.length - 1].y}, {x: pts[0].x, y: pts[0].y});
    t *= res;
    if (t == 1 || t === 0) {
        return true;
    }
    else {
        return false;
    }
};

var DistanceList = function (maxLen) {
    this.maxLen = maxLen;
    this.list = [];
};
DistanceList.prototype.constructor = DistanceList;
DistanceList.prototype.addObject = function (distance, object) {
    if (this.list.length == this.maxLen && distance >= this.list[this.list.length - 1]) {
        return;
    }
    this.list.push({d: distance, obj: object});
    this.list.sort(function (a, b) {
        return a.d - b.d;
    });
    if (this.list.length > this.maxLen) {
        this.list = this.list.splice(0, this.maxLen);
    }
};
DistanceList.prototype.toObject = function () {
    var ret = {};
    for (var i = 0; i < this.list.length; i++) {
        ret[this.list[i].obj.idx] = {obj: this.list[i].obj.sens, d: this.list[i].d};
    }
    return ret;
};
DistanceList.prototype.traverse = function (callback) {
    for (var i = 0; i < this.list.length; i++) callback(this.list[i].d, this.list[i].obj);
};
DistanceList.prototype.reverse = function(){
    this.list.reverse();
};
