'use strict';

/**
 * @description Class to show driver data values for multiple sensors
 * @param {array} objects array of sensors
 * @param {object} pos position for the label
 * @param {object} id the id to set
 * @param {string} container container id
 * @param {function} tempConverter function to convert provided value to user selected temp scale
 * @param {number} maxItemsPerCol maximal number of items per column
 * @param {number} type the physical type to display data for
 * @constructor
 */
function MultiSensorLabel(objects, pos, id, container, tempConverter, maxItemsPerCol, type) {
    this.objs = objects;
    this.container = container;
    this.containerHeight = $('#' + this.container).height();
    this.maxItems = maxItemsPerCol !== undefined ? maxItemsPerCol : 10;
    this.pos = pos;
    this.tempConverter = tempConverter;
    this.id = id;
    this.type = type;
    this.e = this.buildLabel(id, type);
}

MultiSensorLabel.prototype = {};
MultiSensorLabel.prototype.constructor = MultiSensorLabel;
/**
 * @description function to build the label html
 * @param {string} id the id to set for the html element
 * @param {number} type the physical type to display values for
 * @returns {HTMLDivElement}
 */
MultiSensorLabel.prototype.buildLabel = function (id, type) {
    var e = document.createElement("div");
    e.id = id;
    e.labelObj = this;
    document.getElementById(this.container).appendChild(e);
    $(e).addClass("multiSensorLabel");
    $(e).css("max-height", this.containerHeight / 2 + "px");
    $(e).on("mouseenter", function () {
        e.oldZIndex = e.style.zIndex;
        e.style.zIndex = 9000;
        e.style.backgroundColor = "rgba(16,16,16,1)";
    });
    $(e).on("mouseleave", function () {
        e.style.zIndex = e.oldZIndex;
        e.style.backgroundColor = "rgba(16,16,16,0.8)";
    });
    $(e).html(this.buildContent(type));
    for (var i = 0; i < this.objs.length; i++) {
        this.objs[i].hasLabel = true;
    }
    return e;
};
/**
 * @description function to this label from site
 */
MultiSensorLabel.prototype.remove = function () {
    $(this.e).remove();
    this.objs = [];
    this.convert = null;
};
/**
 * @description function to build label content
 * @param {number} type the physical type to display values for
 * @returns {string} returns html content string
 */
MultiSensorLabel.prototype.buildContent = function (type) {
    var html = "<div style='float:right' class='multiLabelCol'>";
    for (var i = 0; i < this.objs.length; i++) {
        if (i / this.maxItems >= 1 && i % this.maxItems == 0) html += "</div><div style='float:left' class='multiLabelCol'>";
        html += this.buildContentSingleSensorForType(this.objs[i], type);// change type here
    }
    html += "</div>";
    return html;
};
/**
 * @description function to build html for single sensor
 * @param {Sensor} sensor the sensor to build content for
 * @param {number} type the physical type to display values for
 * @returns {string} returns html string
 */
MultiSensorLabel.prototype.buildContentSingleSensorForType = function (sensor, type) {
    var html = "<div>";
    html += "<span class='' title='" + sensor.name + "'>"
    html += this.buildValuePanel(sensor, type);
    html += "</span></div>";
    return html;
};
/**
 * @description function to build html for value
 * @param {Sensor} sensor the sensor object to search driver values of with specified physical type
 * @param {number} type the physical type to display
 * @returns {*} returns content string
 */
MultiSensorLabel.prototype.buildValuePanel = function (sensor, type) {
    var values = [];
    for (var i in sensor.driverValues) {
        if (sensor.driverValues[i].physicalType == type) {
            if (!isNaN(sensor.driverValues[i].value) && sensor.driverValues[i].value > -2147483000) {
                if (type == 1) values.push(this.tempConverter(sensor.driverValues[i].value, 2));
                if (type == 5) {
                    values.push(sensor.driverValues[i].value + " Pa");
                }
                else {
                    values.push(sensor.driverValues[i].value + " %");
                }
            }
            else {
                values.push("- - -");
            }
        }
    }
    if (values.length) {
        return values[0];
    }
    else {
        return "- - -";
    }
};
/**
 * @description function to modify this labels position in container object to track a point in 3d-space
 * @param {THREE.OrthographicCamera|THREE.PerspectiveCamera} cam the camera to use to compute the screen space position for the label
 */
MultiSensorLabel.prototype.track = function (cam) {
    cam.updateProjectionMatrix();

    var p = this.pos.clone();

    p.project(cam);

    // console.warn(p.x + "|" + p.y);

    var c = $('#' + this.container);
    var t = c.offset().top;
    var l = c.offset().left;
    var cw = c.width();
    var ch = c.height();
    var w = window.innerWidth;
    var h = window.innerHeight;
    var we = this.e.offsetWidth;
    var he = this.e.offsetHeight;

    var validRect = {min: {x: l, y: t}, max: {x: l + cw, y: t + ch}};

    var tp = (ch / 2 - ch / 2 * p.y - he / 2 + t);
    var lp = (cw / 2 + cw / 2 * p.x - we / 4);
    //
    // if (tp < validRect.min.y) console.warn("top prob");
    // if (tp > validRect.max.y) console.warn("bottom prob");
    // if (lp < validRect.min.x) console.warn("left prob");
    // if (lp > validRect.max.x) console.warn("right prob");

    this.e.style.top = "" + tp + "px";
    this.e.style.left = "" + lp + "px";
};
/**
 * @description function to trigger update for label content
 */
MultiSensorLabel.prototype.updateContent = function () {
    $(this.e).html(this.buildContent(this.type));
};

