/**
 * @description class to handle html object info labels
 * @param {THREE.Object3D} obj object to setup label for
 * @param {object} id the id to set
 * @param {object} container dom element to add the label to
 * @param {object} pos vector object describing where to put the label in 3D-Context
 * @param {function} tempConverter function to handle temperature conversion from celsius to kelvin/fahrenheit
 * @param {function} callbackClick function to be called when button in label is pressed
 * @param {number} langType the language to use
 * @param {function} translator function $translate.instant
 * @param {number} weightType whether to use imperial or metric system (0 - metric, 1 - imperial)
 * @constructor
 */
function ObjectInfoLabel(obj, id, container, pos, tempConverter, callbackClick, langType, translator, weightType) {
    this.obj = obj;
    this.id = id;
    this.container = container;
    this.tempConverter = tempConverter;
    this.langType = langType;
    this.pos = pos;
    this.callbackClick = callbackClick;
    this.translator = translator;
    this.weightType = weightType !== undefined ? weightType : 0;
    this.e = this.buildLabel(id);
}

ObjectInfoLabel.prototype.constructor = ObjectInfoLabel;
ObjectInfoLabel.prototype = {
    /**
     * @description function to build the label content and add the label to parent container
     * @param {object} id the id to be set
     * @returns {HTMLDivElement} returns created html label object
     */
    buildLabel: function (id) {
        var e = document.createElement("div");
        e.id = id;
        e.labelObj = this;
        document.getElementById(this.container).appendChild(e);
        $(e).addClass("objectInfoLabel");
        $(e).html(this.buildContent());
        var _this = this;
        $('#clickObj').on('click', function (e) {
            _this.callbackClick(e);
        });
        return e;
    },
    /**
     * @description function to generate the content for the label
     * @returns {string} return html content string
     */
    buildContent: function () {
        if (this.obj instanceof Sensor) {
            return "<div id='infoLabelContent' style='margin:5px;'>" + this.getSensorContent() + "</div>";
        }
        if (this.obj instanceof Rack) {
            return "<div id='infoLabelContent' style='margin:5px;'>" + this.getRackContent() + "</div>";
        }
        if (this.obj instanceof Asset) {
            return "<div id='infoLabelContent' style='margin:5px;'>" + this.getAssetContent() + "</div>";
        }
        if (this.obj instanceof Cooling) {
            return "<div id='infoLabelContent' style='margin:5px;'>" + this.getCoolingContent() + "</div>";
        }
        if (this.obj instanceof Ups) {
            return "<div id='infoLabelContent' style='margin:5px;'>" + this.getUpsContent() + "</div>";
        }
    },
    /**
     * @description function to create the button in the label
     * @returns {string} return html string for the button
     */
    getButton: function () {
        var disabled = this.obj.id < 0 ? 'disabled' : '';
        return "<span style='pointer-events: auto' class='btn btn-xs btn-primary' id='clickObj' " + disabled + "><span class='icon-info'></span>&nbsp;Details</span><br/>";
    },
    /**
     * @description function to generate basic content for label
     * @returns {string} return html string for the content
     */
    getStdContent: function () {
        var content = "<span>" + this.obj.name + "</span><br/>";
        content += "<span style='pointer-events: auto' class='btn btn-xs btn-primary' id='clickObj'><span class='icon-info'></span>&nbsp;Details</span><br/>";
        return content;
    },
    /**
     * @description function to create label content for asset objects
     * @returns {string} returns html string for asset content
     */
    getAssetContent: function () {
        var content = "<span>" + this.obj.name + "</span><br/>";
        content += this.getDriverValueContent(this.obj.driverValues, false);
        content += this.getButton();
        return content;
    },
    /**
     * @description function to create label content for rack objects
     * @returns {string} returns html string for rack content
     */
    getRackContent: function () {
        var openTranslate = this.translator("room.edit.openViaInfoLabel");
        var freeHUTranslate = this.translator("room.edit.hu");
        var IconTitle = this.translator("room.edit.availableHU");
        var content = "<span>" + this.obj.name + "</span><br/>";
        content += "<span class='icon-slot' style='pointer-events: auto' title='" + IconTitle + "'></span>" + "&nbsp;" + this.obj.getUsedHU() + '&nbsp;/&nbsp;' + this.obj.getHUforRack() + '&nbsp;' + freeHUTranslate + "</span><br/>";
        //TODO buildRackTemperatureHTML possible feature
        content += this.buildRackConsumtionHtml();
        content += this.buildRackWeightHtml();
        var disabled = this.obj.heightUnits == undefined || this.obj.heightUnits < 1 || this.obj.heightUnits > 100 ? 'disabled' : '';
        content += "<span style='pointer-events: auto' class='btn btn-xs btn-primary' id='clickObj' " + disabled + "><span class='glyphicon glyphicon-plus'></span>&nbsp;" + openTranslate + "</span><br/>";
        return content;
    },
    /**
     * @description function to create rack consumption info content for the label
     * @returns {string} return html string for the consumption of the whole rack
     */
    //TODO adjust to design proposal by DL

    buildRackConsumtionHtml: function () {
        var consumptionInfo = this.obj.computeConsumption();
        var valueStyling = '';
        var InfoIcon = '';
        var title = this.translator("room.edit.consumption");
        var icon = "<span id='consumptionInfo' class='icon-power' style='pointer-events: auto' title='" + title + "'></span>";
        var warnMsg = this.translator("room.edit.consumptionInfo");
        if (consumptionInfo.consumption === 0) return "";
        var consumptionType = "Watt"; //TODO maybe more types needed
        if (!consumptionInfo.limitReached) {
            valueStyling = "<span>";
        }
        else {
            valueStyling = "<span class='label-warning border-radius-label-warning'>";
        }
        if (consumptionInfo.fullyDefined) {
            InfoIcon = "";
        }
        else {
            InfoIcon = '&nbsp;<span class="icon-info text-info info-icon-background" title="' + warnMsg + '"></span>';
        }
        var retHtml = "";
        retHtml += icon + "&nbsp;" + valueStyling + consumptionInfo.slotConsumption + '</span>' + InfoIcon + '&nbsp;/&nbsp;<span>' + consumptionInfo.maxConsumption + '&nbsp;' + consumptionType + "</span><br/>";
        return retHtml;
    },

    /**
     * @description function to create rack weight info content for the label
     * @returns {string} return html string for the weight of the whole rack
     */
    buildRackWeightHtml: function () {
        var weightInfo = this.obj.computeWeight();
        var valueStyling = '';
        var infoIcon = '';
        var title = this.translator("room.edit.weight");
        var icon = "<span id='weightInfo' class='icon-weight-mass' style='pointer-events: auto' title='" + title + "'></span>"
        var warnMsgIcon = this.translator("room.edit.weightInfo");
        if (weightInfo.weight === 0) return "";
        if (this.weightType === 1) weightInfo.weight = ObjectInfoLabel.convertWeightToUS(weightInfo.weight);
        if (this.weightType === 1) weightInfo.maxWeight = ObjectInfoLabel.convertWeightToUS(weightInfo.maxWeight);
        if (!weightInfo.limitReached) {
            valueStyling = "<span>";
        }
        else {
            valueStyling = "<span class='label-warning border-radius-label-warning'>";
        }
        if (weightInfo.fullyDefined) {
            infoIcon = '';
        }
        else {
            infoIcon = '&nbsp;<span class="icon-info text-info info-icon-background" title="' + warnMsgIcon + '"></span>';
        }
        var weightUnit = this.weightType === 0 ? "kg" : "lb";
        var retHtml = "";
        retHtml += icon + "&nbsp;" + valueStyling + weightInfo.weight + '</span>' + infoIcon + '&nbsp;/&nbsp;<span>' + weightInfo.maxWeight + '&nbsp;' + weightUnit + "</span><br/>";

        return retHtml;
    },
    /**
     * @description function to create label content for ups objects
     * @returns {string} returns html string for ups objects
     */
    getUpsContent: function () {
        var content = "<span>" + this.obj.name + "</span><br/>";
        content += this.getDriverValueContent(this.obj.driverValues, false);
        content += this.getButton();
        return content;
    },
    /**
     * @description function to create label content for cooling units
     * @returns {string} returns html string for cooling units
     */
    getCoolingContent: function () {
        var content = "";
        content += "<span>" + this.obj.name + "</span><br/>";
        if (this.obj.isStulz() && this.obj.stulzInfo) {
            var infos = this.getStulzInfos();
            if (infos.controlType !== null) {
                content += "<div><span>" + this.getControlTypeStr(infos.controlType) + "</span></div>";
            }
            content += "<div><span>";
            content += infos.temp !== null && infos.temp > -2000000000 ? this.tempConverter(infos.temp, 3) : '- - -';
            content += "</span><span class=" + this.getCoolingModeIcon(this.obj.stulzInfo.coolingMode) + "></span><span class='icon-heating " + (!this.obj.stulzInfo.heatingRunning ? 'icon-inactive' : '') + "'></span></div>";
            content += "<div><span>";
            content += infos.humi !== null && infos.humi > -2000000000 ? infos.humi.toFixed(3) : '- - -';
            content += " % </span><span class='icon-humidification " + (!this.obj.stulzInfo.humiRunning ? 'icon-inactive' : '') + "'></span><span class='icon-dehumidification " + (!this.obj.stulzInfo.dehumiRunning ? 'icon-inactive' : '') + "'></span></div>";
        }
        content += this.getDriverValueContent(this.obj.driverValues, false);
        content += this.getButton();
        content += "";
        return content;
    },
    /**
     * @description function to get string representation to provided control type
     * @param {number} type the control type to get string representation for
     * @returns {string} returns string for the provided control type
     */
    getControlTypeStr: function (type) {
        switch (type) {
            case 1:
                return "room";
            case 2:
                return "supply air";
            case 3:
                return "room (sup.lim)";
            case 4:
                return "sup. (room lim.)";
            case 5:
                return "water press";
            default:
                return "unknown";
        }
    },
    /**
     * @description function to get icon name for provided cooling mode
     * @param {number} mode the cooling mode to get icon for
     * @returns {string} returns the icon name string to use
     */
    getCoolingModeIcon: function (mode) {
        switch (mode) {
            case 0:
                return "icon-cool-none";
            case 1:
                return "icon-cool-fc";
            case 2:
                return "icon-cool-efc";
            case 3:
                return "icon-cool-mix";
            case 4:
                return "icon-cool-dx";
            case 5:
                return "icon-cool-cw";
            case 6:
                return "icon-cool-af";
            default:
                return "icon-cool-none";
        }
    },
    /**
     * @description function to retrieve specific information for stulz cooling units
     * @returns {{controlType: null, temp: null, humi: null}} returns information object
     */
    getStulzInfos: function () {
        /**
         * @description function to search in objects driver value array for driver value with specified oid
         * @param {Object} obj the object to search in
         * @param {string} oid the oid to search for
         * @returns {*} returns driver value if found, otherwise returns dummy object to use
         */
        var getDVByOid = function (obj, oid) {
            for (var i = 0; i < obj.driverValues.length; i++) {
                if (obj.driverValues[i].parameter.oidDataPoint && obj.driverValues[i].parameter.oidDataPoint.oid == oid) return obj.driverValues[i];
            }
            return {value: null};
        };
        var getHeatingRunning = function (obj) {
            if (getDVByOid(obj, "1.1.2.2.2.1.6302").value === 1) return true;
            if (getDVByOid(obj, "1.1.2.2.2.1.6202").value === 1) return true;
            if (getDVByOid(obj, "1.1.2.2.1.1.1.4803").value === 1) return true;
            if (getDVByOid(obj, "1.1.2.2.1.1.1.4903").value === 1) return true;
            if (getDVByOid(obj, "1.1.2.2.1.1.1.5003").value === 1) return true;
            if (getDVByOid(obj, "1.1.2.2.1.1.1.5103").value === 1) return true;
            return false;
        };
        var info = {
            controlType: null,
            temp: null,
            humi: null
            // coolingMode:getDVByOid(this.obj, "1.1.2.1.9.1.1787").value,
            // humiRunning:getDVByOid(this.obj, "1.1.2.3.1.1.6402").value,
            // dehumiRunning:getDVByOid(this.obj, "1.1.2.3.1.1.6802").value,
            // heatingRunning:getHeatingRunning(this.obj)
        };
        var controlType = getDVByOid(this.obj, "1.3.1.1.4.1.1183");
        if (controlType.value !== null) {
            info.controlType = controlType.parameter.entityType;
            if (controlType.value % 2 === 1) {
                info.temp = getDVByOid(this.obj, "1.1.1.1.1.1.1.1192").value;
                info.humi = getDVByOid(this.obj, "1.1.1.1.2.1.1.1194").value;
            }
            else {
                info.temp = getDVByOid(this.obj, "1.1.1.1.1.1.1.1193").value;
                info.humi = getDVByOid(this.obj, "1.1.1.1.2.1.1.1195").value;
            }
        }
        return info;
    },
    /**
     * @description function to create content for sensor labels
     * @returns {string} returns html string for sensor label content
     */
    getSensorContent: function () {
        var content = "<span>" + this.obj.name + "</span><br/>";
        content += this.getDriverValueContent(this.obj.driverValues, true);
        return content;
    },
    /**
     * @description function to get the value to display incl. unit of measurement depending on provided physical type and icons if it is sensor
     * @param {list} driverValues the driverValues list to create label against
     * @param {boolean} isSensor check the driver is sensor or not
     * @returns {string} returns html string for label content
     */
    getDriverValueContent: function (driverValues, isSensor) {
        var content = "";
        for (var i = 0; i < driverValues.length; i++) {
            var driverValue = driverValues[i];
            if (driverValue.useHover) {
                if (driverValue.physicalType === 6) {
                    if (isSensor) {
                        content += "<span>" + this.translator(driverValue.parameter.name) + "</span>: <span>"
                            + this.getDisplayValueIndividualPhyType(driverValue.value, driverValue.physicalType, driverValue.parameter.parameterValues, false)
                            + "</span><br/>";

                    } else {
                        content += "<span>" + this.translator(driverValue.parameter.name) + "</span>: <span><br/>"
                            + this.getDisplayValueIndividualPhyType(driverValue.value, driverValue.physicalType, driverValue.parameter.parameterValues, false)
                            + "</span><br/>";
                    }
                } else {
                    if (isSensor) {
                        if (driverValue.parameter.uniqueLabel !== null && driverValue.parameter.uniqueLabel !== undefined) {
                            if (driverValue.value === undefined || driverValue.value < -2000000) {
                                content += "- - -" + "</span><br/>";
                            } else {
                                content += driverValue.value.toFixed(3) + " " + driverValue.parameter.uniqueLabel
                                +"</span><br/>";
                            }
                        } else {
                            content += this.getDisplayValue(driverValue.value, driverValue.physicalType, isSensor) + "</span><br/>";
                        }
                    } else {
                        if (driverValue.parameter.uniqueLabel !== null && driverValue.parameter.uniqueLabel !== undefined) {
                            if (driverValue.value === undefined || driverValue.value < -2000000) {
                                content += "- - -" + "</span><br/>";
                            } else {
                                content += "<span>" + this.translator(driverValue.parameter.name) + "</span>:<span><br/>"
                                content += driverValue.value.toFixed(3) + " " + driverValue.parameter.uniqueLabel
                                    + "</span><br/>";
                            }
                        } else {
                            content += "<span>" + this.translator(driverValue.parameter.name) + "</span>:<span><br/>"
                                + this.getDisplayValue(driverValue.value, driverValue.physicalType, false) + "</span><br/>";
                        }
                    }
                }
            }
        }
        return content;
    },
    /**
     * @description function to get the value to display incl. unit of measurement depending on provided physical type
     * @param {number} value the value to use
     * @param {number} physType the physical type to use
     * @param {boolean} isSensor check the driver is sensor or not
     * @returns {*} returns the converted value incl. unit of measurement
     */
    getDisplayValue: function (value, physType, isSensor) {
        var inputString = this.checkValue(value);
        switch (physType) {
            case 0:
                var resultant = inputString === "valid" ? value.toFixed(3) : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 1:
                var resultant = inputString === "valid" ? this.tempConverter(value, 3) : inputString;
                return isSensor === true ? "<i class='icon-temp' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 2:
                var resultant = inputString === "valid" ? value.toFixed(3) + " % rel.H." : inputString;
                return isSensor === true ? "<i class='icon-humidity' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 3:
                var resultant = inputString === "valid" ? value.toFixed(3) + " RPM" : inputString;
                return isSensor === true ? "<i class='icon-fan' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 4:
                var resultant = inputString === "valid" ? value.toFixed(3) + " %" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 5:
                var resultant = inputString === "valid" ? value.toFixed(3) + " Pa" : inputString;
                return isSensor === true ? "<i class='icon-pressure' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 7:
                var resultant = inputString === "valid" ? value.toFixed(3) : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 8:
                var resultant = inputString === "valid" ? value.toFixed(3) + " V" : inputString;
                return isSensor === true ? "<i class='icon-voltage' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 9:
                var resultant = inputString === "valid" ? value.toFixed(3) + " A" : inputString;
                return isSensor === true ? "<i class='icon-current' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 10:
                var resultant = inputString === "valid" ? value.toFixed(3) + " W" : inputString;
                return isSensor === true ? "<i class='icon-power' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 11:
                var resultant = inputString === "valid" ? value.toFixed(3) + " Hz" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 12:
                var resultant = inputString === "valid" ? value.toFixed(3) + " W" : inputString;
                return isSensor === true ? "<i class='icon-consumption' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 14:
                var resultant = inputString === "valid" ? value.toFixed(3) + " RPS" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 15:
                var resultant = inputString === "valid" ? value.toFixed(3) + " m³/s" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 16:
                var resultant = inputString === "valid" ? value.toFixed(3) + " s" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 17:
                var resultant = inputString === "valid" ? value.toFixed(3) + " h" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 18:
                var resultant = inputString === "valid" ? value.toFixed(3) + " min" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            case 19:
                var resultant = inputString === "valid" ? value.toFixed(3) + " m" : inputString;
                return isSensor === true ? "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>" + resultant : resultant;
            default:
                return "unknown";
        }
    },
    /**
     * @description function to check the value is valid
     * @param {number} value the value to check
     * @returns {String} returns valid or "---"
     */
    checkValue: function (value) {
        if (value === undefined || value < -2000000) {
            return "- - -";
        }
        return "valid";
    },
    /**
     * @description function to get the value to display depending on individual physical type
     * @param {number} value the value to use
     * @param {number} physType the physical type to use
     * @param {object} parameterValues the parameter list of the driver
     * @returns {*} returns the converted value incl. unit of measurement
     */
    getDisplayValueIndividualPhyType: function (value, physType, parameterValues, isSensor) {
        var iconContent = "<i class='icon-info' style='margin-right:4px; font-size:14px'></i><span>";
        if (value === undefined || value < -2000000) {
            return isSensor === true ? iconContent + "- - -" : "- - -";
        }
        if(parameterValues !== null) {
            for (var i = 0; i < parameterValues.length; i++) {
                if (parseFloat(parameterValues[i].value) === value) {
                    return isSensor === true ? iconContent + parameterValues[i].description : parameterValues[i].description;
                }
            }
        }
        return value;
    },
    /**
     * @descriptionn function to remove this label from parent object
     */
    remove: function () {
        $(this.e).remove();
        this.obj = null;
    },
    /**
     * @description function to modify label position depending on camera position
     * @param {THREE.PerspectiveCamera | THREE.OrthographicCamera} cam the camera to use for computation for label position
     */
    track: function (cam) {
        var p = this.pos.clone();

    var ce = $('#' + this.container);
    var tce = ce.offset().top;
    var lce = ce.offset().left;

    var w = window.innerWidth;
    var h = window.innerHeight;

    var ew = this.e.offsetWidth;
    var eh = this.e.offsetHeight;

    var v = p.project(cam);
    v.x = (v.x + 1) / 2 * (w - ew / 2 - lce);
    v.y = -(v.y - 1) / 2 * (h - eh / 2 - tce);

        this.e.style.top = "" + v.y + "px";
        this.e.style.left = "" + v.x + "px";
    },
    /**
     * @description function to update label content
     */
    updateContent: function () {
        $(this.e).html(this.buildContent());
        var _this = this;
        $('#clickObj').on('click', function (e) {
            _this.callbackClick(e);
        });
        // if(this.obj instanceof Cooling && this.obj.isStulz())  console.log(this.obj.stulzInfo);
    },
    /**
     * @description function to set label position (top, left css)
     * @param {number} top the y-offset
     * @param {number} left the x-offset
     */
    setPositionCSS: function (top, left) {
        this.e.style.top = top + "px";
        this.e.style.left = left + "px";
    }
};

/**
 * @description Function to convert std mass value to US lb with 3 decimal places
 * @param {number} value the value to convert to US lb
 * @returns {number} returns the converted lb value
 */
ObjectInfoLabel.convertWeightToUS = function (value) {
    return Math.round((value / .45359237) * 1000) / 1000;
};

