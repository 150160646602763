var TrackingDivLabel = function (id, obj, containerId) {
    this.id = id === undefined ? null : id;
    this.obj = obj === undefined ? null : obj;
    this.containerId = containerId === undefined ? '' : containerId;
};
TrackingDivLabel.constructor = TrackingDivLabel;
TrackingDivLabel.prototype = {

    buildText: function () {
        this.e = document.createElement("div");
        this.e.textContent = "TEST1234";
        this.e.id = "tdl_" + this.id;
        this.e.style.backgroundColor = "rgba(0,0,0,.5)";
        //this.e.style.color = "rgba(0,0,0,1)";
        //this.e.style.textAlign = "center";
        this.e.style.position = "absolute";
        this.e.style.padding = "1px 1px";
        //this.e.style.border = "1px solid";
        this.e.style.borderRadius = "2px";
        this.e.style.maxWidth = "4em";
        this.e.style.overflowY = "auto";
        this.e.style.cursor = "default";
        this.e.style.pointerEvents = "auto";
        document.getElementById(this.containerId).appendChild(this.e);
    },
    buildInput: function () {
        this.buildText();
        $('#' + this.e.id).html("<input id='i" + this.e.id + "' type='text' class='overlayInput'>");
    },
    setContent: function (content) {

    },
    allowPointerEvents: function (allow) {
        if (allow) {
            this.e.style.pointerEvents = "auto";
        } else {
            this.e.style.pointerEvents = "none";
        }
    },
    track: function (cam) {

        cam.updateProjectionMatrix();

        var p = null;
        if (this.obj instanceof THREE.Vector3) p = this.obj.clone();
        if (this.obj instanceof THREE.Object3D) p = this.obj.position.clone();

        p.project(cam);

        // console.warn(p.x + "|" + p.y);

        var c = $('#' + this.containerId);
        var t = c.offset().top;
        var l = c.offset().left;
        var cw = c.width();
        var ch = c.height();
        var w = window.innerWidth;
        var h = window.innerHeight;
        var we = this.e.offsetWidth;
        var he = this.e.offsetHeight;

        var validRect = {min: {x: l, y: t}, max: {x: l + cw, y: t + ch}};

        var tp = (ch / 2 - ch / 2 * p.y - he / 2 + t);
        var lp = (cw / 2 + cw / 2 * p.x + we / 4);


        //check if bottom is intersected by the divLabel
        if (tp > validRect.max.y) {
            // console.warn("bottom intersecting");
            this.e.style.display = 'none';
        }
        if (tp < validRect.max.y) {
            // console.warn("bottom not intersecting intersecting");
            this.e.style.display = 'unset';
        }

        //possible setup to perform actions on other directions intersection
        // if (tp < validRect.min.y) {
        //     console.warn("top intersecting");
        // }
        // if (lp < validRect.min.x) {
        //     console.warn("left intersecting");
        // }
        // if (lp > validRect.max.x) {
        //     console.warn("right intersecting");
        // }


        this.e.style.top = "" + tp + "px";
        this.e.style.left = "" + lp + "px";

    },
    setValue: function (value) {
        $('#i' + this.e.id).val(value);
        $('#i' + this.e.id).attr('ov', value);
    },
    getValue: function () {
        return $('#i' + this.e.id).val();
    },
    setOnChangeCallback: function (callback) {
        if (!callback) return;
        $('#i' + this.e.id).on('change', function (e) {
            callback(e);
        });
    },
    setOnInputCallback: function (callback) {
        if (!callback) return;
        $('#i' + this.e.id).on('input', function (e) {
            callback(e);
        });
    },
    setOnKeyDownCallback: function (callback) {
        if (!callback) return;
        $('#i' + this.e.id).on('keydown', function (e) {
            callback(e);
        });
    },
    setOnBlurCallback: function (callback) {
        if (!callback) return;
        $('#i' + this.e.id).on("blur", function (e) {
            callback(e);
        });
        // $('#i'+this.e.id).focusout(function(e){
        //     callback(e);
        // });
    },
    setTrackedValue: function (valueName) {
        $('#i' + this.e.id).attr('trackedValue', valueName);
    },
    setObj: function (obj) {
        this.obj = obj;
    },
    getObj: function () {
        return this.obj;
    },
    remove: function () {
        $('#' + this.e.id).remove();
    },
    insert: function () {
        document.getElementById(this.containerId).appendChild(this.e);
    },
    getId: function () {
        return this.id;
    }
};
